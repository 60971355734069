/* eslint-disable import/prefer-default-export */
// this is a lil easter egg, put @marker(triggerMeatball) into the NLP to see it in action
export const meatballString = `            ▒▒▓▓▒▒▓▓░░
        ▒▒▒▒▓▓▒▒░░  ▒▒▓▓▓▓          ███╗░░░███╗███████╗░█████╗░████████╗██████╗░░█████╗░██╗░░░░░██╗░░░░░
    ▓▓▓▓▒▒▒▒░░░░░░░░░░░░▓▓▓▓▒▒  ██  ████╗░████║██╔════╝██╔══██╗╚══██╔══╝██╔══██╗██╔══██╗██║░░░░░██║░░░░░
    ░░░░  ░░░░░░░░░░  ▒▒░░░░▓▓      ██╔████╔██║█████╗░░███████║░░░██║░░░██████╦╝███████║██║░░░░░██║░░░░░
  ▒▒░░░░    ░░░░░░░░░░░░░░░░░░░░    ██║╚██╔╝██║██╔══╝░░██╔══██║░░░██║░░░██╔══██╗██╔══██║██║░░░░░██║░░░░░
  ░░░░░░░░▒▒░░░░▒▒░░░░░░  ▒▒░░▓▓    ██║░╚═╝░██║███████╗██║░░██║░░░██║░░░██████╦╝██║░░██║███████╗███████╗
    ░░░░░░▒▒░░    ░░▒▒▒▒░░░░░░▒▒    ╚═╝░░░░░╚═╝╚══════╝╚═╝░░╚═╝░░░╚═╝░░░╚═════╝░╚═╝░░╚═╝╚══════╝╚══════╝
  ░░░░░░░░░░░░  ▓▓░░░░░░░░░░▒▒░░
  ░░▒▒░░░░░░░░  ░░░░░░░░    ░░▓▓    ░██████╗██╗░░░██╗███╗░░░███╗███╗░░░███╗░█████╗░███╗░░██╗███████╗██████╗░
  ▒▒░░░░░░░░▒▒▒▒░░░░░░░░░░░░░░▓▓    ██╔════╝██║░░░██║████╗░████║████╗░████║██╔══██╗████╗░██║██╔════╝██╔══██╗
  ░░  ░░░░░░░░░░  ░░░░▒▒▒▒▓▓░░░░    ╚█████╗░██║░░░██║██╔████╔██║██╔████╔██║██║░░██║██╔██╗██║█████╗░░██║░░██║
▓▓    ░░░░░░▒▒▒▒▒▒▒▒██▒▒▒▒░░▒▒  ░░  ░╚═══██╗██║░░░██║██║╚██╔╝██║██║╚██╔╝██║██║░░██║██║╚████║██╔══╝░░██║░░██║
        ░░▒▒▒▒░░░░░░░░▒▒▒▒▒▒        ██████╔╝╚██████╔╝██║░╚═╝░██║██║░╚═╝░██║╚█████╔╝██║░╚███║███████╗██████╔╝
        ░░  ░░░░▒▒░░▒▒░░░░          ╚═════╝░░╚═════╝░╚═╝░░░░░╚═╝╚═╝░░░░░╚═╝░╚════╝░╚═╝░░╚══╝╚══════╝╚═════╝░
`;
